<template>
  <base-view id="current-video">
    <div
      class="flex flex-col lg:flex-row lg:justify-center w-full lg:px-24 my-0 "
    >
      <div class="w-full lg:w-1/3">
        <video-sub-nav title="Plan Videos" />
      </div>
      <div
        class="flex flex-row mb-6 overflow-auto lg:flex-col w-full lg:w-2/3 lg:pl-12"
      >
        <div class="pt-0 lg:pt-16 pb-16 w-full h-full">
          <div v-if="!videoId && !title">
            <p>Loading...</p>
          </div>
          <div v-else class="w-full h-full">
            <h1 class="mb-12 text-4xl ml-4 text-left font-semibold">
              {{ title }}
            </h1>
            <div class="flex flex-row flex-wrap justify-between">
              <div class="w-full h-full text-center">
                <div class="mb-4 w-full h-full">
                  <iframe
                    v-if="!isVimeo"
                    class="mx-auto lg:mx-4 mb-12 border border-neutral border-solid rounded w-full sm:w-3/4 h-96 "
                    :src="
                      `https://fast.wistia.net/embed/iframe/${videoId}?seo=false&playerColor=006840`
                    "
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                  />
                  <iframe
                    v-else
                    class="mx-auto lg:mx-4 mb-12 border border-neutral border-solid rounded w-full sm:w-3/4 h-96"
                    :src="
                      `https://player.vimeo.com/video/${videoId}?h=0ec833cd06&badge=0&autopause=0&player_id=0&app_id=58479 [player.vimeo.com]`
                    "
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import VideoSubNav from "@/components/layout/VideoSubNav.vue";

export default {
  components: {
    BaseView,
    VideoSubNav
  },
  data() {
    return {
      videos: {
        jn0h9tael7: "FSA Overview",
        qm5id14mng: "DCA Overview",
        narmv3kr4s: "HSA Overview",
        "97bsjir1cp": "LPF Overview",
        "5pfymqwdww": "Commuter Overview",
        k4nqf77rxp: "HRA Overview",
        thvr22tw51: "WealthCare Saver HSA High Yield",
        zjfzh4rvnd: "FSA/HSA/HRA - Comparison of Accounts",
        zn2gnc2gew: "FSA/HSA - What Items are Eligible?",
        rsybt90qxy: "Open Enrollment - Managing HealthCare Finances",
        xy41yyou4y: "Open Enrollment - How Much Should I Be Saving?",
        "5h3nbakrz9": "Open Enrollment - Choosing A Health Insurance"
      },
      vimeo: {
        "288778849": "HSA Devenir Guide",
        "780805966":
          "HSA Devenir Investment - How Do I Use this Investment Tool?"
      },
      isVimeo: false,
      title: "",
      videoId: this.$route.params.video
    };
  },
  mounted() {
    if (this.videos[this.videoId]) {
      this.isVimeo = false;
      this.title = this.videos[this.videoId];
    } else {
      this.isVimeo = true;
      this.title = this.vimeo[this.videoId];
    }
  },
  watch: {
    $route() {
      if (this.$route.params.video) {
        this.videoId = this.$route.params.video;
        if (this.videos[this.videoId]) {
          this.isVimeo = false;
          this.title = this.videos[this.videoId];
        } else {
          this.isVimeo = true;
          this.title = this.vimeo[this.videoId];
        }
      }
    }
  }
};
</script>
