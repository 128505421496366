<template>
  <div class="w-auto h-full mx-4 my-0 lg:max-w-6xl ">
    <div
      class="flex flex-col justify-start w-auto h-full pt-16 pb-16 my-0 lg:flex-row"
    >
      <div class="flex flex-row">
        <div class="w-full text-left lg:w-72 nav first:mt-0">
          <h3 class="mb-2 text-xl font-semibold">{{ title }}</h3>
          <div class="flex flex-row mb-6 overflow-auto lg:flex-col">
            <router-link
              v-for="route in routes"
              :key="route.video"
              :to="`/plan-videos/${route.video}`"
              class="block p-2 text-base transition-colors border-b rounded-t lg:border-b-0 whitespace-nowrap lg:rounded-b hover:bg-neutral-light active:bg-neutral cursor-pointer"
            >
              {{ route.description }}
            </router-link>
          </div>
        </div>
        <div class="hidden mx-4 border-r lg:block" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  data() {
    return {
      routes: [
        { video: "jn0h9tael7", description: "FSA Overview" },
        { video: "qm5id14mng", description: "DCA Overview" },
        { video: "narmv3kr4s", description: "HSA Overview" },
        { video: "97bsjir1cp", description: "LPF Overview" },
        { video: "5pfymqwdww", description: "Commuter Overview" },
        { video: "k4nqf77rxp", description: "HRA Overview" },
        { video: "288778849", description: "HSA Devenir Guide" },
        { video: "780805966", description: "HSA Devenir Investment" },
        { video: "thvr22tw51", description: "WealthCare Saver HSA High Yield" },
        { video: "zjfzh4rvnd", description: "FSA/HSA/HRA - Comparison" },
        {
          video: "zn2gnc2gew",
          description: "FSA/HSA - What Items are Eligible?"
        },
        { video: "rsybt90qxy", description: "Managing HealthCare Finances" },
        { video: "xy41yyou4y", description: "How Much Should I Be Saving?" },
        { video: "5h3nbakrz9", description: "Choosing A Health Insurance" }
      ]
    };
    /**
     * zn2gnc2gew: "What items are elligible?",
        fo3vt4l169: "How does my debit card work?",
        jaw27m0mdd: "How do I register for a direct deposit?",
        "33oc95u3ef": "How do I complete my profile?",
        rmnvj4h23q: "How do I enroll in text alerts?",
        l43se5rk77: "How do I check my balance?",
        ymiebya71z: "How do I file a claim?",
        hgdfjxundu: "How do I make a bill payment?",
        w069p3jvbk: "How do I download the mobile app?",
        rjze84w3jy: "How do I view my HSA statement?",
        "5h3nbakrz9": "Open Enrollment - Choosing A Health Insurance"
     */
  }
};
</script>

<style scoped>
a.router-link-active {
  color: theme("colors.primary.light");
  background-color: theme("colors.neutral.light");
  border-bottom: 0.25rem solid theme("colors.primary.light");
  border-right: none;
}

@media only screen and (min-width: 1024px) {
  a.router-link-active {
    border-right: 0.25rem solid theme("colors.primary.light");
    border-bottom: none;
  }
}
</style>
